import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@app/materialUi/theme';
import { isBridgeTricorn, setupFreighterWalletWatcher } from '@app/helpers/helpers';

function App() {
    const [importedComponent, setImportedComponent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        const importComponent = async () => {
            const module = await import(
                isBridgeTricorn
                    ? './TricornApp'
                    : './RunesKeysApp'
            );
            const BridgeApp = module.default;
            setImportedComponent(<BridgeApp />);
        };

        importComponent();
    }, []);

    const dispatch = useDispatch();
    setupFreighterWalletWatcher(dispatch);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {importedComponent}
        </ThemeProvider>
    );
};

export default App;
